<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-card-title class="d-flex justify-space-between mb-3">
            Courses
            <div>
              <v-btn
                color="primary"
                @click="$router.push({ name: 'create-course' })"
              >
                <v-icon>mdi-plus</v-icon>
                Create New
              </v-btn>
            </div>
          </v-card-title>
          <v-data-table
            item-key="id"
            class="elevation-1 data-table"
            :loading="getCourseLookupLoading"
            :headers="headers"
            :items="getSortedCourse || []"
            :disable-filtering="true"
          >
            <template v-slot:item.is_scorm="{ item }">
              <v-icon v-if="item.is_scorm" color="secondary">mdi-check</v-icon>
            </template>
            <template v-slot:item.created="{ item }">
              <div v-if="item.is_scorm" color="secondary">{{ item.scorm.created_at | datetime }}</div>
              <div v-else>{{ item.course.created_at | datetime }}</div>
            </template>
            <template v-slot:item.modified="{ item }">
              <div v-if="item.is_scorm" color="secondary">{{ item.scorm.updated_at | datetime }}</div>
              <div v-else>{{ item.course.updated_at | datetime }}</div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu
                origin="bottom center"
                :offset-x="true"
                :offset-y="true"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" small v-bind="attrs" v-on="on">
                    Manage
                    <v-icon right class="ml-0">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="py-1">
                  <v-list-item @click="editCourse(item, item.is_scorm)">
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="clone(item)">
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-content-duplicate</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Clone Course</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!item.is_scorm"
                    @click="viewCourseItems(item)"
                  >
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-book-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Course Items</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="exportParticipants(item)">
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-file-excel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Export Participants</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="exportCourse(item)">
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Export To Mail</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!item.is_scorm" @click="exportText(item)">
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-note-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Export To Text</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="removeCourse(item)">
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </base-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="20%">
      <v-card v-if="selectedCourse">
        <v-card-title> Delete {{ selectedCourse.title }}? </v-card-title>
        <v-card-text>
          All data connected to this course will be permanently deleted. This is
          no longer reversable. Are you sure you want to continue?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="danger" text @click="onSubmit"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exportDialog" max-width="20%">
      <v-card v-if="selectedCourse">
        <v-card-title> Export Mail? </v-card-title>
        <v-card-text>
          Are you sure you want to send an export email?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="exportDialog = false"> Cancel </v-btn>
          <v-btn color="primary" text @click="exportMail">Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { assign, get, isEmpty, sortBy } from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Courses",
  },
  data() {
    return {
      model: "course",
      dialog: false,
      exportDialog: false,
      isEdit: false,
      selectedCourse: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        pageCount: 1,
      },

      headers: [
        { text: "Title", value: "title" },
        {
          text: "Passing Score",
          value: "passing_score",
          align: "center",
          sortable: false,
        },
        {
          text: "Max Course Try",
          value: "max_course_try",
          align: "center",
          sortable: false,
        },
        {
          text: "Is Scorm",
          value: "is_scorm",
          align: "center",
          sortable: false,
        },
        {
          text: "Created",
          value: "created",
          align: "center",
          sortable: false,
        },
        {
          text: "Modified",
          value: "modified",
          align: "center",
          sortable: false,
        },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
    };
  },

  methods: {
    ...mapActions([
      "setCourseLookup",
      "deleteCourseLookup",
      "cloneCourse",
      "exportCourseParticipants",
      "exportCourseMail",
      "exportCourseText",
    ]),

    editCourse(course, isScorm) {
      const id = get(course, "is_scorm")
        ? get(course, "scorm_id")
        : get(course, "id");

      this.$router.push({
        name: isScorm ? "edit-scorm" : "edit-course",
        params: { id, isScorm: get(course, "is_scorm") },
      });
    },

    async clone(course) {
      const isScorm = get(course, "is_scorm");
      const id = isScorm ? get(course, "scorm_id") : get(course, "course_id");

      const response = await this.cloneCourse({
        id,
        isScorm,
      });

      if (!isEmpty(response)) {
        this.$store.dispatch("showSnackbar", "The course has been cloned.");
      }
    },

    async exportParticipants(course) {
      const isScorm = get(course, "is_scorm");
      const id = isScorm ? get(course, "scorm_id") : get(course, "course_id");

      const params = { id };

      if (isScorm) {
        assign(params, { isScorm });
      }

      const response = await this.exportCourseParticipants(params);

      if (!isEmpty(response)) {
        const fileName = `${moment().format(
          "YYYY-MM-DD"
        )}-course-participants.csv`;

        const url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
      }
    },

    async exportMail() {
      const isScorm = get(this.selectedCourse, "is_scorm");
      const id = isScorm
        ? get(this.selectedCourse, "scorm_id")
        : get(this.selectedCourse, "course_id");

      const params = { id };

      if (isScorm) {
        assign(params, { isScorm });
      }

      const response = await this.exportCourseMail(params);

      if (!isEmpty(response)) {
        this.$store.dispatch("showSnackbar", response);
      }

      this.selectedCourse = null;
      this.exportDialog = false;
    },

    async exportText(course) {
      const id = get(course, "course_id");
      const response = await this.exportCourseText({ id });

      if (!isEmpty(response)) {
        const fileName = `${get(
          course,
          "course.description"
        )}-${moment().format("YYYY-MM-DD")}.txt`;

        const url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
      }
    },

    removeCourse(course) {
      this.dialog = true;
      this.selectedCourse = course;
    },

    exportCourse(course) {
      this.exportDialog = true;
      this.selectedCourse = course;
    },

    viewCourseItems(course) {
      this.$router.push({
        path: `/app/course/${get(course, "course_id")}/course-item`,
      });
    },

    async onSubmit() {
      await this.deleteCourseLookup(this.selectedCourse.id);

      await this.setCourseLookup();
      this.selectedCourse = null;
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters(["getCourseLookup", "getCourseLookupLoading"]),

    getSortedCourse() {
      return sortBy(this.getCourseLookup, "id");
    },
  },
  filters: {
    datetime(value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
};
</script>
<style lang="scss" scoped></style>
